<template>
<v-container>
    <form>

        <v-row>
            <v-col cols="12">
                <v-text-field :data-cy="`tableTo${node.demoNodeIndex}`" color="primary" :error="(errors?.tableTo)?true:false" :hint="errors.tableTo?.join(' - ')" outlined dense v-model="data.step.tableTo" label="Step Name" @input="onChange(data.step, 'tableTo')"></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="4">
                <v-select :data-cy="`dataset${node.demoNodeIndex}`" @focus="refreshCols()" item-color="primary" color="primary" v-model="data.step.tableFrom2Complete" @change="getColumns()" dense outlined label="Select Dataset to Join" :items="allDatasets" item-text="pretty_name" item-value="dataset_id" return-object :error="(errors?.tableFrom2Complete)?true:false" @input="onChange(data.step, 'tableFrom2Complete')" :hint="errors.tableFrom2Complete?.join(' - ')" persistent-hint item-disabled="acyclic"></v-select>
            </v-col>
            <v-col cols="4">
                <v-select :data-cy="`baseColumn${node.demoNodeIndex}`" item-color="primary" color="primary" dense outlined label="Base Dataset Join Column" :items="data.step.tableFrom1Columns" item-text="pretty_name" item-value="column_name" v-model="data.step.tableFrom1JoinColumn" :error="(errors?.tableFrom1JoinColumn)?true:false" @input="onChange(data.step, 'tableFrom1JoinColumn')" :hint="errors.tableFrom1JoinColumn?.join(' - ')" persistent-hint></v-select>
            </v-col>
            <v-col cols="4">
                <v-select :data-cy="`joinColumn${node.demoNodeIndex}`" item-color="primary" color="primary" dense outlined label="Joined Dataset Join Column" :items="data.step.tableFrom2Columns" item-text="pretty_name" item-value="column_name" v-model="data.step.tableFrom2JoinColumn" :error="(errors?.tableFrom2JoinColumn)?true:false" @input="onChange(data.step, 'tableFrom2JoinColumn')" :hint="errors.tableFrom2JoinColumn?.join(' - ')" persistent-hint></v-select>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-select :data-cy="`keepColumns${node.demoNodeIndex}`" item-color="primary" color="primary" multiple dense small-chips outlined label="Joined Dataset Columns To Keep" :items="data.step.tableFrom2Columns" item-text="pretty_name" item-value="column_name" v-model="data.step.tableFrom2ColumnsKeep" return-object :error="(errors?.tableFrom2ColumnsKeep)?true:false" @input="onChange(data.step, 'tableFrom2ColumnsKeep')" :hint="errors.tableFrom2ColumnsKeep?.join(' - ')" persistent-hint></v-select>
            </v-col>

            <v-col cols="12">
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :data-cy="`saveButton${node.demoNodeIndex}`" @click="emitDataAction()" color="primary"> {{data.node.data.status === 'unconfigured' ? 'Save' : 'Update'}} </v-btn>
                </v-card-actions>
            </v-col>
        </v-row>
    </form>
</v-container>
</template>

<script>
import pipelinesApi from '@/services/pipelines';
import {
    userStore
} from '@/stores/UserStore';
import formRules from '@/utils/validation/formRules'
import nodeCode from '@/utils/pipelines/nodeCode'
import {
    emitData
} from '@/utils/eventBus/'

export default {
    name: 'JoinNode',
    mixins: [formRules, nodeCode],
    props: {
        node: {
            type: Object,
            default: () => {
                return {}
            }
        },
        index: {
            type: Number,
            default: () => 0
        }
    },
    setup() {
        const user = userStore()
        return {
            // you can return the whole store instance to use it in the template
            user,
        }
    },

    data() {
        return {
            allDatasets: [],
            errors: {},
            data: {
                step: {},
                rules: {
                    tableTo: {
                        rule: 'notEmpty'
                    },
                    tableFrom2Complete: {
                        rule: 'notEmptyObject'
                    },
                    tableFrom1JoinColumn: {
                        rule: 'notEmptyArray'
                    },
                    tableFrom2JoinColumn: {
                        rule: 'notEmptyArray'
                    },
                    tableFrom2ColumnsKeep: {
                        rule: 'notEmptyArray'
                    },
                }
            }
        }
    },

    async created() {
        
        this.processParentData();
        this.data.step.type = 'join'

        await pipelinesApi.getAcyclicDatasets(this.user.getCurrentWorkspace, this.data.step.schemaTo, this.data.step.tableToDatabaseName)
            .then(res => {
                this.allDatasets = res.data;
            })
            .catch(err => {
               console.error(err)
            })

    },

    computed: {

        disabledRules() {
            let isDisabled = false;
            for (const key in this.errors) {
                if (this.errors[key] != null) {
                    isDisabled = true
                }
            }
            if (
                !this.data.step.tableTo ||
                !this.data.step.tableFrom2Complete ||
                !this.data.step.tableFrom1JoinColumn ||
                !this.data.step.tableFrom2JoinColumn ||
                this.data.step.tableFrom2ColumnsKeep.length < 1
            ) {
                isDisabled = true
            }

            return isDisabled;
        },
    },

    methods: {

        emitDataAction() {

            // Update properties in place

            this.data.step.schemaFrom2 = this.data.step.tableFrom2Complete.schema;
            this.data.step.tableFrom2DatabaseName = this.data.step.tableFrom2Complete.name;

            // Filter out columns with invalid names
            const validColumns = this.data.step.tableFrom2Columns.map(x => x.column_name)
            this.data.step.tableFrom2ColumnsKeep = this.data.step.tableFrom2ColumnsKeep.filter(x => validColumns.includes(x.column_name))

            this.data.step.tableFrom2ColumnsKeepRenamed = structuredClone(this.data.step.tableFrom2ColumnsKeep)

            // Rename columns in tableFrom2ColumnsKeepRenamed and append them to tableToColumns
            const tableFrom2PrettyName = this.data.step.tableFrom2Complete.pretty_name
            const tableFrom2NotPrettyName = tableFrom2PrettyName.replace(/\s/g, '_').toLowerCase();

            this.data.step.tableToColumns = structuredClone(this.data.step.tableFrom1Columns)
            for (let obj of this.data.step.tableFrom2ColumnsKeepRenamed) {
                
                obj.pretty_name = `${tableFrom2PrettyName} ${obj.display_name}`
                obj.column_name = `${tableFrom2NotPrettyName}_${obj.display_name.replace(/\s/g, '_').toLowerCase()}`
                this.data.step.tableToColumns.push(obj)
            }

            emitData('emit-data', this.data);

        }
    }
}
</script>
