<template>
<v-container>
    <form>

        <v-row>
            <v-col cols="6" class="pt-0">
                <v-text-field data-cy="transformationCalculateTableTo" color="primary" :hint="errors.tableTo?.join(' - ')" outlined dense v-model="data.step.tableTo" label="Step Name" :error="(errors?.tableTo)?true:false" @input="onChange(data.step, 'tableTo')" persistent-hint></v-text-field>
            </v-col>
            <v-col cols="6" class="pt-1">
                <v-chip>BETA Feature</v-chip>
            </v-col>

            <v-col cols="12" class="py-01">
                <v-tabs  v-model="tab" class="mt-n8">
                    <v-tab>Editor</v-tab>
                    <v-tab>Preview</v-tab>
                    <v-tab>Column Mapping</v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab" class="pt-4">
                    <v-tab-item>
                        <v-textarea @click:clear="clearTextArea()" rows="3" auto-grow outlined label="Prefix SQL" v-model="data.step.prefix" :error="(errors?.frontendFormula)?true:false" @input="onChange(data.step, 'sqlCode')" :hint="errors.frontendFormula?.join(' - ')" readonly persistent-hint>
                        </v-textarea>
                        <v-textarea :readonly="user.email.indexOf('@ivp.ai') === -1" @click:clear="clearTextArea()" rows="15" auto-grow outlined label="SQL" v-model="data.step.frontendFormula" :error="(errors?.frontendFormula)?true:false" @input="onChange(data.step, 'sqlCode')" :hint="errors.frontendFormula?.join(' - ')" clearable persistent-hint>
                        </v-textarea>
                    </v-tab-item>
                    <v-tab-item>
                        <v-data-table :headers="previewHeaders" :items="previewData" hide-default-footer></v-data-table>                            
                    </v-tab-item>
                    <v-tab-item>
                        <v-row v-for="(item, index) in data.step.tableToColumns" :key="index" class="mb-n7">
                            <v-col cols="6"><v-text-field label="column_name" outlined dense readonly v-model="data.step.tableToColumns[index].column_name" disabled></v-text-field></v-col>
                            <v-col cols="6"><v-select label="Data Type" outlined dense v-model="data.step.tableToColumns[index].data_type_id" :items="dataTypes" item-text="label" item-value="value"></v-select></v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>

            </v-col>


            <v-col cols="12">
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="validateSql()">Validate</v-btn>
                    <v-btn :data-cy="`saveButton${node.demoNodeIndex}`" @click="emitDataAction()" color="primary"> {{data.node.data.status === 'unconfigured' ? 'Save' : 'Update'}} </v-btn>
                </v-card-actions>
            </v-col>
            <!--
            <v-col cols="10" class="pt-3 pb-0">
                <v-select item-color="primary" color="primary" dense small-chips outlined label="Select a Column to Include" :items="data.step.tableFromNumericalColumns" item-text="pretty_name" item-value="column_name" v-model="data.step.columnToAdd" :error="(errors?.columnToAdd)?true:false" @input="onChange(data.step, 'columnToAdd')" :hint="errors.columnToAdd?.join(' - ')" persistent-hint></v-select>
            </v-col>
            <v-col cols="2" class="pt-3 pb-0">
                <v-btn color="primary" @click="addToFormula(' ' + data.step.columnToAdd)" block>
                    <v-icon left>fa-thin fa-plus</v-icon>Add
                </v-btn>
            </v-col>
            <v-col cols="12" class="py-0">
                <v-card-actions class="py-0 pr-1">
                    <v-spacer></v-spacer>
                    <v-btn v-if="data.node.data.status === 'unconfigured'" @click="emitDataAction" :disabled="disabledRules" color="primary">Save</v-btn>
                    <v-btn v-if="data.node.data.status !== 'unconfigured'" @click="emitDataAction" :disabled="disabledRules" color="primary">Update</v-btn>
                </v-card-actions>
            </v-col>
            -->
        </v-row>
    </form>
</v-container>
</template>

<script>
import {
    emitData
} from '@/utils/eventBus'
import formRules from '@/utils/validation/formRules'
import nodeCode from '@/utils/pipelines/nodeCode'
import pipelinesApi from '@/services/pipelines';
import {
   userStore
} from '@/stores/UserStore';

export default {
    setup() {
      const user = userStore()
      return {
         user,
      }
   },
    name: 'CalculateNode',

    mixins: [formRules, nodeCode],

    props: {
        node: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },

    data() {
        return {
            errors: {},
            tab: 'editor',
            previewHeaders: [],
            previewData: [],
            dataTypes: [
                {value: 1, label: 'Number'},
                {value: 2, label: 'Text'},
                {value: 3, label: 'Timestamp'},
                {value: 4, label: 'Boolean'},
                {value: 5, label: 'Geo Lat/Lng'},
            ],
            data: {
                step: null,
                rules: {
                    tableTo: {
                        rule: 'notEmpty'
                    },
                    newColumnName: {
                        rule: 'notEmpty'
                    },
                    frontendFormula: {
                        rule: 'notEmpty'
                    },
                    tableFromColumns: {
                        rule: 'notEmpty'
                    }
                }
            }
        }
    },

    created() {

        this.processParentData()
        this.data.step.type = 'customsql'
        this.data.step.prefix = 'WITH source AS (\n SELECT * FROM previous_step\n)'

    },

    computed: {

        disabledRules() {
            let isDisabled = false;
            for (const key in this.errors) {
                if (this.errors[key] != null) {
                    isDisabled = true
                }
            }
            if (
                !this.data.step.tableTo ||
                !this.data.step.newColumnName ||
                !this.data.step.frontendFormula ||
                !this.data.step.tableFromColumns
            ) {
                isDisabled = true
            }
            return isDisabled;
        },

    },

    methods: {
        validateSql() {
            
            const previous_step = `"${this.data.step.schemaFrom}"."${this.data.step.tableFrom1DatabaseName}"`
            const sql = `
            WITH source AS (
                SELECT * FROM ${previous_step} LIMIT 50
            )

            ${this.data.step.frontendFormula}
            `
            pipelinesApi.validateCustomSql(sql)
                .then(response => {
                    
                    this.previewHeaders = Object.keys(response.data[0]).map(key => {
                        return {
                            text: key,
                            value: key
                        }
                    })
                    this.previewData = response.data;
                    this.data.step.tableToColumns = Object.keys(response.data[0]).map(key => {
                        return {
                            data_type_id: 2,
                            column_name: key,
                            pretty_name: key
                        }
                    })

                })
                .catch(error => {
                    console.error(error)
                })
        },
        clearTextArea() {

            this.data.step.frontendFormula = ''
            this.data.step.backendFormula = ''
            this.$forceUpdate();

            this.onChange(this.data.step, 'frontendFormula')

        },


        addToFormula(frontendItem, backendItem) {

            this.data.step.frontendFormula === null ? this.data.step.frontendFormula = '' : {}
            this.data.step.backendFormula === null ? this.data.step.backendFormula = '' : {}

            this.data.step.frontendFormula = this.data.step.frontendFormula + frontendItem;
            this.data.step.backendFormula = this.data.step.backendFormula + backendItem;
            this.$forceUpdate();

            this.onChange(this.data.step, 'frontendFormula')

        },

        emitDataAction() {
            
            this.data.step.type = 'customsql'
            emitData('emit-data', this.data);
        }
    }
}
</script>
